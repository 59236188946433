import axios from "axios";

export default {
  methods: {
    sendRequest(payload) {
      return new Promise((resolve, reject) => {
        var _vm = this;

        _vm.loading = true;
        axios
          .get(this.$BASE_URL + payload["endpoint"], { headers: {} })
          .then(function(response) {
            if (
              response.data.message &&
              response.data.message.includes("Empty database")
            )
              return;

            if (payload["list"] == true)
              _vm[payload["store_as"]] = response.data;
            else _vm[payload["store_as"]] = response.data[0];
            _vm.loading = false;
            setTimeout(function() {
              _vm.expandedAlbum = 0;
            }, 600);
            resolve();
          })
          .catch(function(error) {
            console.log(error);
            reject();
          });
      });
    },
    sendRequestComments(payload) {
      return new Promise((resolve, reject) => {
        var _vm = this;
        axios
          .get(this.$BASE_URL + payload["endpoint"], { headers: {} })
          .then(function(response) {
            if (
              response.data.message &&
              response.data.message.includes("Empty database")
            )
              return;

            let array = response.data;
            let result_array = [];

            for (let c in array) {
              // We add it if it has no parent
              if (!array[c].parent_id) result_array.push(array[c]);
            }

            for (let c in array) {
              if (array[c].parent_id != undefined) {
                // If it's a children
                for (let cc in result_array) {
                  if (result_array[cc].id == array[c].parent_id) {
                    if (result_array[cc].comments == undefined)
                      result_array[cc].comments = [];
                    result_array[cc].comments.unshift(array[c]);
                  }
                }
              }
            }
            _vm[payload["store_as"]] = result_array;
            _vm.$emit("loadedComments", result_array.length);
            resolve();
          })
          .catch(function(error) {
            _vm.$emit("loadedComments", 0);
            console.log(error);
            reject();
          });
      });
    }
  }
};
