import Vue from "vue";
import VueRouter from "vue-router";
import TheNews from "../views/News.vue";
import TheForum from "../views/Forum.vue";
import TheDiscography from "../views/Discography.vue";
import TheTeam from "../views/Team.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/news" },
  {
    path: "/news",
    name: "News",
    component: TheNews
  },
  {
    path: "/news/:news_id",
    name: "News",
    component: TheNews
  },
  {
    path: "/forum",
    name: "Forum",
    component: TheForum
  },
  {
    path: "/forum/:topic_id",
    name: "Forum",
    component: TheForum
  },
  {
    path: "/team",
    name: "Team",
    component: TheTeam
  },
  {
    path: "/discography",
    name: "Discography",
    component: TheDiscography
  },
  {
    path: "/discography/:composer_id",
    name: "Discography",
    component: TheDiscography
  },
  {
    path: "/discography/:composer_id/project/:project_id",
    name: "Discography",
    component: TheDiscography
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
