<template>
  <v-app class="main">
    <div class="backgroundCover"></div>
    <v-app-bar app color="secondary" class="menu elevation-12">
      <v-app-bar-nav-icon
        @click="drawer = true"
        v-if="$vuetify.breakpoint.smAndDown"
      ></v-app-bar-nav-icon>
      <div class="ml-4">
        <router-link to="/news">
          <v-img src="~@/assets/hans_zimmer_logo.png" width="80" height="38" />
        </router-link>
      </div>

      <v-spacer></v-spacer>
      <v-row
        justify="space-around"
        style="max-width: 750px"
        v-if="$vuetify.breakpoint.mdAndUp"
        class="ml-12 mr-n12"
      >
        <v-col v-for="menu in menus" :key="menu">
          <v-btn :to="'/' + menu" text active-class="" class="text-center">
            <v-img
              v-if="menu == currentMenu"
              src="~@/assets/fioriture.svg"
              width="18"
              height="18"
              contain
              style="transform: scaleX(-1)"
            ></v-img>
            <span
              class="mx-4"
              :class="menu == currentMenu ? 'white--text' : 'grey--text'"
            >
              {{ menu }}
            </span>
            <v-img
              v-if="menu == currentMenu"
              src="~@/assets/fioriture.svg"
              width="18"
              height="18"
              contain
            ></v-img>
          </v-btn>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <TheSearch />
      <Login />
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item v-for="menu in menus" :key="menu" :to="'/' + menu">
            <v-list-item-title>
              <v-btn text active-class="" class="text-center">
                <v-img
                  v-if="menu == currentMenu"
                  src="~@/assets/fioriture.svg"
                  width="18"
                  height="18"
                  contain
                  style="transform: scaleX(-1)"
                ></v-img>
                <span
                  class="mx-3"
                  :class="menu == currentMenu ? 'white--text' : 'grey--text'"
                >
                  {{ menu }}
                  <span class="grey--text caption" v-if="menu == 'forum'">
                    bêta
                  </span>
                </span>
                <v-img
                  v-if="menu == currentMenu"
                  src="~@/assets/fioriture.svg"
                  width="18"
                  height="18"
                  contain
                ></v-img>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {
    TheSearch: () => import("./components/TheSearch.vue"),
    Login: () => import("./components/buttons/Login.vue")
  },
  data: () => ({
    menus: ["news", "discography", "team", "forum"],
    drawer: false
  }),
  methods: {
    // login: function () {
    //   this.$auth.login({ email, password }).then(function () {
    //     // Execute application logic after successful login
    //   })
    // },
    // register: function () {
    //   this.$auth.register({ name, email, password }).then(function () {
    //     // Execute application logic after successful registration
    //   })
    // }
  },
  computed: {
    currentMenu() {
      return this._.toLower(this.$route.name);
    }
  }
};
</script>
<style lang="sass">

.tempImage
  z-index: 10 !important
  position: absolute !important
  height: 100vh
  width: 100vw

.v-btn--active:before
  opacity: 0 !important

.line-breaks
  white-space: pre-line

.backgroundCover
  width: 100%
  height: 100%
  position: fixed
  background-image: url('~@/assets/fond_large_rouge.jpg') !important
  background-size: cover
  background-position: top

.menu
  background-image: url('~@/assets/fioriture_menu.png') !important
  background-size: 30%
  background-position-y: -80px !important

.cursor-pointer
  cursor: pointer

.capitalize
  text-transform: uppercase

.round
  border-radius: 500px

.rounded
  border-radius: 7px

*::-webkit-scrollbar:not(.displayscroll)
  display: none

*
  scrollbar-color: dark !important

.break-word
  display: inline-block
  word-break: break-word

.pointer
  cursor: pointer

.noscroll::-webkit-scrollbar:not(.displayscroll)
  display: none

*::-webkit-scrollbar
  height: 12px
  width: 12px
  background: #222

*::-webkit-scrollbar-thumb
  background: #1a2f2f
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75)

*::-webkit-scrollbar-corner
  background: #222
</style>
