import Vue from "vue";
import App from "./App.vue";
import _ from "lodash";
import "./filters.js";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { VueReCaptcha } from "vue-recaptcha-v3";

import GAuth from "vue-google-oauth2";

let google_client_id =
  "166263424391-253ujasqq0evov7e6b2ofj67lqm43av2.apps.googleusercontent.com";

if (window.location.href.includes("beta.hans-zimmer.com")) {
  Vue.prototype.$BASE_URL = "https://beta.hans-zimmer.com/api";
} else if (window.location.href.includes("hans-zimmer.com")) {
  Vue.prototype.$BASE_URL = "https://hans-zimmer.com/api";
} else {
  Vue.prototype.$BASE_URL = "http://localhost:8000/api";
  google_client_id =
    "1060936845504-45s62g0hfpntp7cptdpp3mlileq9tka2.apps.googleusercontent.com";
}

const gauthOption = {
  clientId: google_client_id,
  scope: "openid profile email",
  prompt: "select_account"
};
Vue.use(GAuth, gauthOption);

// For more options see below
Vue.use(VueReCaptcha, { siteKey: "6LeQPRUaAAAAAOfoJ1glWeIUDTIl--0dDdsJZxBF" });

var s = Sentry.init({
  Vue,
  dsn:
    "https://5feeab89202546359532544a543e5f4c@o330952.ingest.sentry.io/1852235",
  autoSessionTracking: true,
  logErrors: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.001
});

console.log("SENTRY VUE", s, Vue);

Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, "_", { value: _ });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

function onSignIn(data) {
  console.log("USRE DATA" + data);
}

Object.defineProperty(Vue.prototype, "onSignIn", { value: onSignIn });
