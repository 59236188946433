import Vue from "vue";
import _ from "lodash";

Vue.filter("truncate", function(value, length, separator) {
  if (!value) return "";
  return _.truncate(value, { length: length, separator: separator });
});

Vue.filter("soundtrackTitle", function(value) {
  if (!value) return "";
  return value.split("(")[0];
});

Vue.filter("soundtrackSubtitle", function(value, array) {
  if (!value) return "";
  if (!value.split("(")[1]) return "";
  if (value.split("(")[1].includes("AKA")) return "";
  if (array) {
    var arrayvalue = value.split("(");
    arrayvalue = arrayvalue.splice(1, arrayvalue.length);
    for (var a in arrayvalue) {
      arrayvalue[a] = arrayvalue[a].split(")")[0];
    }
    return arrayvalue;
  } else {
    return value.split("(")[1].split(")")[0];
  }
});

Vue.filter("soundtrackAKA", function(value) {
  if (!value) return "";
  if (!value.split("(")[1]) return "";
  if (!value.split("(")[1].includes("AKA")) return "";
  return value.split("(")[1].split(")")[0];
});

function sameDay(d1, d2) {
  return (
    d1.getUTCFullYear() == d2.getUTCFullYear() &&
    d1.getUTCMonth() == d2.getUTCMonth() &&
    d1.getUTCDate() == d2.getUTCDate()
  );
}

Vue.filter("parseDate", function(date) {
  if (date == undefined) return null;

  const options = { year: "numeric", month: "short", day: "numeric" };
  const dateParsed = new Date(Date.parse(date));
  let day = "";
  let today = new Date();

  if (sameDay(dateParsed, today)) {
    day = "today";
  } else if (
    sameDay(
      dateParsed,
      new Date(Date.parse(today.setDate(today.getDate() - 1)))
    )
  ) {
    day = "yesterday";
  } else {
    day = _.capitalize(dateParsed.toLocaleDateString("en-EN", options));
  }
  return (
    (dateParsed.getHours() < 10 ? "0" : "") +
    dateParsed.getHours() +
    "h" +
    (dateParsed.getMinutes() < 10 ? "0" : "") +
    dateParsed.getMinutes() +
    " " +
    day
  );
});

Vue.filter("parseDateShort", function(date) {
  const options = { weekday: "short", month: "long", day: "numeric" };
  return _.capitalize(
    new Date(Date.parse(date)).toLocaleDateString("en-EN", options)
  );
});

Vue.filter("parseDateSlash", function(date) {
  const options = { month: "2-digit", day: "2-digit", year: "numeric" };
  return _.capitalize(
    new Date(Date.parse(date)).toLocaleDateString("fr-FR", options)
  );
});
