import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { setStore, getStore } from "@/plugins/utils";

Vue.use(Vuex);
Vue.config.devtools = true;

const user = getStore("user");

export default new Vuex.Store({
  state: {
    loginUser: user,
    bottomYear: 1956,
    currentYear: 2022,
    artists: [],
    news: [],
    projects: [],
    topics: [],
    loading: false,
    popupProject: null
  },
  getters: {
    currentYear: state => state.currentYear,
    bottomYear: state => state.bottomYear,
    artists: state => state.artists,
    news: state => state.news,
    topics: state => state.topics,
    projects: state => state.projects,
    popupProject: state => state.popupProject,
    loginUser: state => state.loginUser
  },
  mutations: {
    mutate(state, payload) {
      if (payload.property == "loginUser") {
        setStore("user", payload.with);
      }
      state[payload.property] = payload.with;
    }
  },
  actions: {
    getMoreData(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(this._vm.$BASE_URL + payload["endpoint"], { headers: {} })
          .then(function(response) {
            let data = store.state[payload["store_as"]];
            data = data.concat(response.data);

            if (
              response.data.message &&
              response.data.message.includes("Empty database")
            )
              return resolve("no more");

            store.commit("mutate", {
              property: payload["store_as"],
              with: data
            });
            resolve();
          })
          .catch(function(error) {
            console.log(error);
            reject();
          });
      });
    },
    getData(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(this._vm.$BASE_URL + payload["endpoint"], { headers: {} })
          .then(function(response) {
            if (
              response.data.message &&
              response.data.message.includes("Empty database")
            )
              return resolve("no more");

            store.commit("mutate", {
              property: payload["store_as"],
              with: response.data
            });
            resolve();
          })
          .catch(function(error) {
            console.log(error);
            reject();
          });
      });
    },
    postData(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(this._vm.$BASE_URL + payload["endpoint"], {
            headers: {},
            data: payload["data"]
          })
          .then(function(response) {
            if (payload["store_as"] != undefined) {
              store.commit("mutate", {
                property: payload["store_as"],
                with: response.data
              });
              resolve(response.data);
            }
          })
          .catch(function(error) {
            console.log(error);
            reject();
          });
      });
    }
  },
  modules: {}
});
