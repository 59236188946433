var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"width":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-center"},[_c('v-col',{class:{ 'leftPannel ml-12': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"cols":"12","md":"3"}},[_c('v-row',[_c('v-col',{class:{ 'mt-n5': _vm.$vuetify.breakpoint.xs },attrs:{"cols":"12","sm":"6","md":"12"}},[_c('WidgetUsers',{staticClass:"mt-3 mx-auto",style:({
              width: '100%',
              maxWidth: _vm.$vuetify.breakpoint.xs ? '' : '300px'
            })})],1),_c('v-col',{class:{ 'mt-n5': _vm.$vuetify.breakpoint.xs },attrs:{"cols":"12","sm":"6","md":"12"}},[_c('WidgetProjects',{staticClass:"mt-3 mx-auto",style:({
              width: '100%',
              maxWidth: _vm.$vuetify.breakpoint.xs ? '' : '300px'
            })})],1)],1)],1),(_vm.loading)?_c('v-col',{staticClass:"mt-3 centerPannel",attrs:{"cols":"12","md":"3","offset-md":"5"}},[_c('TheLoader')],1):_c('v-col',{staticClass:"centerPannel",class:{
        'mt-n5': _vm.$vuetify.breakpoint.xs,
        'mt-3': _vm.$vuetify.breakpoint.mdAndUp
      },attrs:{"cols":"12","md":"4","offset-md":"4"}},[_c('div',[_c('AdminPublish')],1),_c('div',[_c('ItemQuestion')],1),(_vm.$vuetify.breakpoint.xs)?_c('div',{staticClass:"title text-left ml-5 mb-n3"},[_vm._v(" Latest News ")]):_vm._e(),(!_vm.isSpecificNews)?_c('ListNews'):_c('div',[_c('v-row',[_c('v-btn',{staticClass:"mb-10 secondary",on:{"click":_vm.returnToNewsList}},[_vm._v("← Return to News List")])],1),_c('v-row',[(_vm.specificNews)?_c('ItemNews',{attrs:{"news":_vm.specificNews}}):_vm._e()],1)],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"mt-3 centerPannel",class:{ 'rightPannel mr-12': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"cols":"12","md":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('TheCollectible')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('NextEvents')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('a',{staticClass:"d-inline-block",attrs:{"href":"https://www.facebook.com/HansZimmerCom","target":"_blank"}},[_c('v-img',{style:({ opacity: hover ? 0.7 : 1 }),attrs:{"height":"20","width":"20","src":require("@/assets/fb_grey.png")}})],1)]}}],null,false,1760233357)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('a',{staticClass:"d-inline-block ml-3",attrs:{"href":"https://www.youtube.com/user/hanszimmercom","target":"_blank"}},[_c('v-img',{style:({ opacity: hover ? 0.7 : 1 }),attrs:{"height":"20","width":"90","src":require("@/assets/youtube_grey.png")}})],1)]}}],null,false,3440945003)})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }