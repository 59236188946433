<template>
  <div>
    <v-row class="background-black-velvet pa-7 elevation-24">
      <v-col cols="12" md="4" class="pa-7">
        <v-img src="@/assets/zimmer.jpg"></v-img>
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="illuminate font-weight-bold"
        :class="{ caption: $vuetify.breakpoint.mdAndDown }"
        style="text-align: justify; margin-"
      >
        <!-- @mousemove="illuminate" -->

        {{
          $options.filters.truncate(
            'Hans Florian Zimmer Man born 12 September 1957) is a German film score composer and record producer. Zimmer\'s works are notable for integrating electronic music sounds with traditional orchestral arrangements. Since the 1980s, he has composed music for over 150 films. His works include The Lion King, for which he won the Academy Award for Best Original Score in 1995, the Pirates of the Caribbean series, Interstellar, Gladiator, Crimson Tide, Inception, Dunkirk, and The Dark Knight Trilogy. He has received four Grammy Awards, three Classical BRIT Awards, two Golden Globes, and an Academy Award. He was also named on the list of Top 100 Living Geniuses, published by The Daily Telegraph. \
        Zimmer spent the early part of his career in the United Kingdom before moving to the United States. He is the head of the film music division at DreamWorks studios and works with other composers through the company that he founded, Remote Control Productions, formerly known as Media Ventures. His studio in Santa Monica, California has an extensive range of computer equipment and keyboards, allowing demo versions of film scores to be created quickly.\
        Zimmer has collaborated on multiple projects with directors including Ridley Scott, Ron Howard, Gore Verbinski, Michael Bay, and Christopher Nolan\
        Zimmer began his career playing keyboards and synthesizers in the 1970s, with the band Krakatoa. He worked with the Buggles, a new wave band formed in London in 1977 with Trevor Horn, Geoff Downes, and Bruce Woolley. Zimmer can be seen briefly in the Buggles\' music video for the 1979 song "Video Killed the Radio Star". After working with the Buggles, he started to work for the Italian group Krisma, a new wave band formed in 1976 with Maurizio Arcieri and Christina Moser. He was a featured synthesist for Krisma\'s third media, Cathode Mamma. He has also worked with the band Helden (with Warren Cann from Ultravox). Both Zimmer (on keyboards) and Cann (on drums), were invited to be part of the Spanish group Mecano for a live performance in Segovia (Spain) in 1984. Two songs from this concert were included in the "Mecano: En Concierto" media released in 1985 only in Spain. In 1985, he contributed to the Shriekback media Oil & Gold.',
            textLength,
            " "
          )
        }}
        <v-btn text @click="updateTextLength">{{
          isOpenReadMore ? "SEE LESS" : "READ MORE"
        }}</v-btn>
      </v-col>
    </v-row>
    <v-row class="pa-12" v-for="(name, index) in jobList" :key="index">
      <v-col cols="12"
        ><span class="display-1 font-weight-bold">{{ name }}</span></v-col
      >
      <v-col
        cols="4"
        md="2"
        v-for="artist in artists[name]"
        :key="artist.id"
        class="text-center"
      >
        <v-hover v-slot:default="{ hover }">
          <div
            class="cursor-pointer"
            @click="$router.push('/discography/' + artist.id)"
          >
            <v-img
              v-if="artist.photo"
              :src="artist.photo"
              width="100"
              height="100"
              class="round mx-auto mb-3"
              :class="hover ? 'elevation-24' : ''"
            ></v-img>
            <v-img
              v-else
              src="@/assets/LOGO_AVATAR.jpg"
              width="100"
              height="100"
              class="round mx-auto mb-3"
              :class="hover ? 'elevation-24' : ''"
            ></v-img>
            <span class="pt-5" :class="hover ? 'font-weight-bold' : ''">{{
              artist.name
            }}</span>
          </div>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TheTeam",
  data() {
    return {
      fetchingData: -1,
      textLength: 440
    };
  },
  computed: {
    isOpenReadMore() {
      return this.textLength > 440;
    },
    jobList() {
      let artists = this.$store.getters["artists"];
      let jobList = [
        // Kind of ordered object
        "Composer",
        "Co-Composer",
        "Musician",
        "Arranger",
        "Hans-Zimmer.com"
      ];

      for (let artist of artists)
        if (jobList.findIndex(job => job === artist.job.title) == -1)
          jobList.push(artist.job.title);

      return jobList;
    },
    artists() {
      let artists = this.$store.getters["artists"];
      let artist_obj = {};

      for (let artist of artists) {
        if (artist.job.title == "undefined" || artist.job.title == undefined)
          continue;

        if (!artist_obj[artist.job.title]) artist_obj[artist.job.title] = [];

        artist_obj[artist.job.title].push(artist);
      }
      return artist_obj;
    }
  },
  methods: {
    updateTextLength() {
      this.textLength = this.isOpenReadMore ? 440 : 10000;
    },
    illuminate(e) {
      let spans = document.querySelectorAll(".illuminate span");
      for (let i = 0; i < spans.length; i++) {
        spans[i].style.opacity =
          100 / this.mouseDistanceFromElement(e, spans[i]);
      }
    },
    mouseDistanceFromElement(mouseEvent, element) {
      let $n = element,
        mX = mouseEvent.pageX,
        mY = mouseEvent.pageY,
        from = { x: mX, y: mY },
        off = $n.getBoundingClientRect(),
        ny1 = off.top + document.body.scrollTop, //top
        ny2 = ny1 + $n.offsetHeight, //bottom
        nx1 = off.left + document.body.scrollLeft, //left
        nx2 = nx1 + $n.offsetWidth, //right
        maxX1 = Math.max(mX, nx1),
        minX2 = Math.min(mX, nx2),
        maxY1 = Math.max(mY, ny1),
        minY2 = Math.min(mY, ny2),
        intersectX = minX2 >= maxX1,
        intersectY = minY2 >= maxY1,
        to = {
          x: intersectX ? mX : nx2 < mX ? nx2 : nx1,
          y: intersectY ? mY : ny2 < mY ? ny2 : ny1
        },
        distX = to.x - from.x,
        distY = to.y - from.y,
        hypot = (distX ** 2 + distY ** 2) ** (1 / 2);
      return Math.floor(hypot); //this will output 0 when next to your element.
    }
  },
  updated() {
    if (this.$store.getters["artists"].length == 0 && this.fetchingData == -1) {
      this.fetchingData = 0;
      this.$store.dispatch("getData", {
        endpoint: "/get/artist?relations=job",
        store_as: "artists"
      });
    }
  },
  created() {
    if (this.$store.getters["artists"].length == 0 && this.fetchingData == -1) {
      this.fetchingData = 0;
      this.$store.dispatch("getData", {
        endpoint: "/get/artist?relations=job",
        store_as: "artists"
      });
    }
  }
};
</script>
<style lang="sass">
.illuminate span
  opacity: 0.8
  transition: 0.3s all ease

.background-black-velvet
  background-image: url('~@/assets/fond_noir.jpg')
  background-size: cover
</style>
